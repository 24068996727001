import { Fragment, useEffect } from 'react';
import TinyTitle from '../../components/tinytitle/TinyTitle';
import './ecosystem.css';
import webAppImg from '../../assets/images/web-app.png';
import mobileAppImg from '../../assets/images/mobile-app.png';
import browserExtImg from '../../assets/images/browser-extension.png';
import Helmet from 'react-helmet';
import {defaultMetaDescription, defaultOgImg} from "../../assets/js/blogConfig";

const Ecosystem = () => {

    useEffect(() => {
        setTimeout(() => window.scrollTo(0, 0), 500);
    },[]);

    return(
        <Fragment>
            <Helmet>
                <title>Ecosystem | SharePass</title>
                <meta property="description" content={defaultMetaDescription} />
                <meta property="og:title" content="Ecosystem | SharePass" />
                <meta property="og:description" content={defaultMetaDescription} />
                <meta property="og:image" content={defaultOgImg} />
            </Helmet>
            <section className="ecosystem_landing">
                <div className="content_sec">
                    <div className="bigrid">
                        <div className="text_side">
                            <TinyTitle title="Multi-platform unified privacy" textcolor="#fff" />
                            <h1>Ecosystem</h1>
                            <p>At SharePass, we aim to ensure a seamless user experience. We're dedicated to making our encrypted links universally accessible. That's why we're branching out across various platforms, offering tailored solutions for each.</p>
                        </div>
                    </div>               
                </div>
            </section>
            <section className="mission_vision">
                <div className="content_sec ecosystem-section">
                    <div>
                        <div className="bigrid" style={{opacity: '1', transition: 'all 1.5s ease-in-out 0s'}}>
                            <div className="text_wrap">
                                <h2>Web Application</h2>
                                <p>Access SharePass effortlessly without any additional installations. Log into our web application using your preferred browser on your laptop or desktop. It's designed to serve anyone with an account, making security uncomplicated and accessible.</p>
                                <div className='btn_wrap' style={{margin: '50px 0'}}>
                                    <a href="https://sharepass.com/signup" target='_blank' class="clickable first">Try now</a>
                                </div>
                            </div>
                            <div className="img_wrap">
                                <img src={webAppImg} alt="Web application" />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="content_sec ecosystem-section">
                    <div>
                        <div className="bigrid" style={{opacity: '1', transition: 'all 1.5s ease-in-out 0s'}}>
                            <div className="img_wrap img-desktop">
                                <img src={mobileAppImg} alt="vision" />
                            </div>
                            <div className="text_wrap">
                                <h2>Mobile Application</h2>
                                <p>Recognizing that much of today's confidential communication occurs on mobile devices, SharePass offers a mobile app with a Keyboard extension specifically for mobile use. This ensures that users can transmit private information without leaving traces in their everyday communication tools.</p>
                                <div className='btn_wrap' style={{margin: '50px 0'}}>
                                    <a href="https://apps.apple.com/app/sharepass/id1627340541" target='_blank' class="clickable first">Download</a>
                                </div>
                            </div>
                            <div className="img_wrap img-mobile">
                                <img src={mobileAppImg} alt="vision" />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="content_sec ecosystem-section">
                    <div>
                        <div className="bigrid" style={{opacity: '1', transition: 'all 1.5s ease-in-out 0s'}}>
                            <div className="text_wrap">
                                <h2>Browser Extension</h2>
                                <p>Tailored for frequent users managing sensitive information daily, whether it's credentials, sensitive documents, or configuration files, SharePass's browser extension facilitates swift, inline sharing using convenient context menu tools.</p>
                                <div className='btn_wrap' style={{margin: '50px 0'}}>
                                    <a href="https://chrome.google.com/webstore/detail/sharepass/hbejbbjkjojenjopiinjjegokobgdcbo" target='_blank' class="clickable first">Download</a>
                                </div>
                            </div>
                            <div className="img_wrap">
                                <img src={browserExtImg} alt="vision" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </Fragment>
    )
}

export default Ecosystem;