import "./faq.css";
import { Fragment, useEffect, useState } from "react";
import TermsLanding from "../../components/termslanding/TermsLanding";
import Helmet from 'react-helmet';
import { defaultMetaDescription, defaultOgImg } from "../../assets/js/blogConfig";

const FAQ = () => {
  useEffect(() => {
    setTimeout(() => window.scrollTo(0, 0), 500);
  }, []);

  const [activeIndex, setActiveIndex] = useState(0);

  const questionsAndAnswers = [
    {
      question: 'What is SharePass, and how does it work?',
      answer: <p>SharePass is a secure data sharing platform designed to protect your confidential information while enabling easy and convenient sharing. It works by encrypting your data using advanced encryption algorithms (AES 256 GCM), creating unique SharePass links that you can send to recipients through various communication channels. When a recipient clicks the link, they can securely access the shared information without the need for SharePass registration.</p>,
    },


    {
      question: 'Why should I use SharePass?',
      answer: <>
                <p>When you share confidential information over traditional channels such as notes, SMS, chatting tools, or emails, you leave a digital footprint. Unknowingly, this footprint remains available in clear text, allowing it to be discovered by hackers and other users.</p>
                <p>When using SharePass, you can securely share the required information by encrypting it and sending the link to the recipient. Once the recipient has opened the link, it becomes inactive, leaving no traces of your confidential data.</p>
              </>,
    },

    {
      question: 'How do I create a SharePass account?',
      answer: <>
      <p>You can create a SharePass account using one of two methods:</p>



      <h4 class="wp-block-heading">Method 1: Standard Account Creation</h4>


  
      <ol type="1">
        <li>Visit the SharePass Website: Start by visiting the official SharePass website.</li>



        <li>Register: Click on the <a href="https://sharepass.com/signup">“Sign Up”</a> button, located at the top-right corner of the webpage.</li>



        <li>Provide Information: Fill out the registration form with the required information. This includes your name, email address and a secure password. You will need to verify your email account.</li>



        <li>Optional Multi-Factor Authentication (MFA): For enhanced security, you can enable multi-factor authentication (MFA) during or after the registration process. MFA adds an extra layer of protection to your account.</li>
      </ol>



      <h4 class="wp-block-heading">Method 2: Social Sign-On</h4>



      <p>SharePass also offers the convenience of social sign-on, which allows you to use your existing Google, WWPass, or Apple account to create a SharePass account. Here’s how:</p>



      <ol type="1">
        <li>Visit the SharePass Website: Start by visiting the official SharePass website.</li>



        <li>Select Social Sign-On: Look for the option to sign up or log in using your preferred social platform (Google, WWPass, or Apple).</li>



        <li>Authenticate: Click on your chosen social platform’s icon and follow the authentication prompts. You’ll need to log in to your social account or confirm your identity.</li>



        <li>SharePass Account Creation: Once authenticated, SharePass will create an account linked to your chosen social platform.</li>
      </ol>



      <p>These two methods provide flexibility when creating a SharePass account, allowing you to choose the approach that suits you best. Whether you opt for the standard account creation process or the convenience of social sign-on, you’ll be on your way to securely sharing secrets and sensitive information with SharePass.</p>


    </>,
    },
    {
      question: 'How do I reset my SharePass account password?',
      answer: <>
      <p>Here’s how to reset the password for both standard email accounts and those using social sign-on with SharePass:</p>



      <h4 class="wp-block-heading">For Standard Email Accounts</h4>



      <p>If you have a SharePass account linked to your standard email address (not using social sign-on), follow these steps to reset your password:</p>



      <ol type="1">
        <li>Visit the SharePass Login Page: Go to the SharePass login page on your web browser or mobile app.</li>



        <li>Click on ‘Forgot Password?’ You will be redirected to the password reset page.</li>



        <li>Enter Your Email Address: On the password reset page, enter the email address associated with your SharePass account.</li>



        <li>Receive a Password Reset Link: You will receive an email containing a password reset link. Click on this link to proceed with resetting your password.</li>



        <li>Create a New Password: Follow the on-screen instructions to create a new, secure password for your SharePass account.</li>



        <li>Log In with Your New Password: Once you’ve successfully reset your password, return to the SharePass login page and log in using your email address and the newly created password.</li>
      </ol>



      <h4 class="wp-block-heading">For Social Sign-On Accounts</h4>



      <p>If you have a SharePass account linked to your social media or third-party account, such as Google or Apple, resetting your SharePass password is not applicable. Instead, you will manage your SharePass account through your social media account’s security settings.</p>



      <p>To recover or reset your SharePass account password in this case, you should follow the password recovery or reset process for the social media or third-party account that you use to sign in to SharePass. Each social media platform, like Google or Apple, has its own password recovery methods and settings.</p>



      <p>If you face any issues during this process, you should refer to the support and assistance resources provided by the specific social media platform or third-party service you use to sign in to SharePass.</p>


    </>,
    },
    {
      question: 'What are SharePass Links, and How Do I Use Them?',
      answer: <>
        <p>SharePass links are encrypted URLs that allow you to share secrets and confidential data securely. To use them:</p>

        <ol type="1">
          <li>Create a secret on SharePass.</li>



          <li>Generate a SharePass link.</li>



          <li>Share the link via email, chat, SMS, or any other communication channel.</li>



          <li>Recipients can access the secret by clicking the link and, if required, they may need to present some form of authorization (PIN, MFA, or security keys).</li>
        </ol>
      </>
    },
    {
      question: 'Can SharePass read my secrets?',
      answer: <>
        <p>No, SharePass cannot read your secrets. SharePass is designed with a zero-knowledge architecture, meaning that it has no access to the content of the secrets you create or share. Here’s how this works:</p>



        <ul>
          <li>Client-Side Encryption: When you create a SharePass secret, the encryption process takes place entirely on your device (client-side). SharePass generates a symmetric encryption key, which is used to encrypt your secret data. This key never gets transmitted to SharePass.</li>



          <li>Encrypted Transmission: Only the encrypted data is sent to SharePass’s servers for storage and sharing. SharePass has no knowledge of the encryption key or the content of your secret. The data is transmitted securely using AES 256 GCM encryption protocol.</li>



          <li>Decryption on the Recipient’s Device: When someone with the appropriate permissions accesses the secret, the encrypted data is retrieved from SharePass. Decryption occurs on the recipient’s device using the encryption key provided through the SharePass link. Once again, SharePass never has access to this key.</li>



          <li>Zero-Knowledge Principle: SharePass follows the zero-knowledge principle, ensuring that your secrets remain private and confidential. SharePass, as a service provider, does not have the ability to read or access the content of your secrets, and the encryption keys are kept solely in the hands of the sender and authorized recipients.</li>
        </ul>



        <p>In summary, SharePass is committed to maintaining the privacy and security of your secrets. It employs strong encryption practices and a zero-knowledge architecture to ensure that your sensitive information remains completely inaccessible to anyone other than you and authorized recipients. Your secrets are always kept private and secure when using SharePass.</p>
      </>
    },
    {
      question: 'What if the SharePass link goes into the wrong hands?',
      answer: <>
        <p>If a SharePass link falls into the wrong hands, it’s important to understand that SharePass employs several security measures to protect the confidentiality and integrity of your shared secrets. Here are some key points to consider:</p>
        <ol type="1">
          <li>PIN Protection: Depending on your settings, your secrets may be further protected by a PIN, or security key. Without this additional authentication, unauthorized users won’t be able to access the secret, even if they have the link.</li>
          <li>Expiry and Access Control: SharePass allows you to set expiration dates for your secrets and control who can access them. If the link falls into the wrong hands after it expires or is revoked, it becomes useless.</li>
          <li>IP Restrictions: You can configure SharePass to allow access only from specific IP addresses. This adds an extra layer of security, ensuring that only authorized devices can access the secret.</li>
          <li>Security Keys: If you’ve enabled security keys, they act as a second factor of authentication. Even if someone has the link, they will still need the physical security key to access the secret.</li>
        </ol>
        <p>To further minimize the risk of SharePass links falling into the wrong hands:</p>
        <ul>
          <li>Always use strong PINs to protect your secrets.</li>
          <li>Be cautious about whom you share links with, especially if they contain highly sensitive information.</li>
          <li>Regularly review your SharePass activity and revoke access to any secrets that you no longer wish to share.</li>
          <li>Keep your security keys secure and do not share them with others.</li>
        </ul>
        <p>While SharePass takes robust security measures to safeguard your secrets, it’s essential to use it responsibly and apply additional security layers as needed for the level of confidentiality required.</p>
      </>
    },
    {
      question: 'What is the difference between an “erased” secret and a “deleted” secret?',
      answer: <p>In SharePass, an “erased” secret means that the main secret content in the database is deleted, but associated attributes like creation time, security settings, access logs, etc., are retained. On the other hand, when a secret is “deleted,” the entire record in the database, including all its attributes, is permanently eliminated.</p>
    },
    {
      question: 'Can I share secrets with recipients who don’t have a SharePass account?',
      answer: <p>Yes, SharePass allows you to share secrets with recipients who may not have a SharePass account. They can access the shared content using the provided link and any necessary authentication methods.</p>
    },
    {
      question: 'Is SharePass 100% effective when transmitting confidential data to a recipient?',
      answer: <p>SharePass takes extensive security measures to protect your confidential data. However, no system can guarantee 100% effectiveness due to factors beyond its control, such as the recipient’s environment. SharePass ensures strong encryption and access controls, but the security of data in transit and at the recipient’s end also depends on the recipient’s compliance with security practices and the security of their device. While SharePass provides a highly secure platform, users should exercise caution and ensure their recipients follow secure practices to maximize the protection of confidential data.</p>
    }
  ];

  const toggleAnswer = (index) => {
    setActiveIndex(index === activeIndex ? -1 : index);
  };

  return (
    <Fragment>
      <Helmet>
        <title>FAQ | SharePass</title>
        <meta property="description" content={defaultMetaDescription} />
        <meta property="og:title" content="FAQ | SharePass" />
        <meta property="og:description" content={defaultMetaDescription} />
        <meta property="og:image" content={defaultOgImg} />
      </Helmet>
      <TermsLanding title="FAQ" />
      <section className="faq">
        <div className="content_sec">
          <h2>FAQ</h2>


          <div>
            {questionsAndAnswers.map((item, index) => (
              <div key={index} className="faq-item">
                <div
                  className={`question ${index === activeIndex ? 'active' : ''}`}
                  onClick={() => toggleAnswer(index)}
                >
                  <h3>
                    <span>{index === activeIndex ? <>&#8722;</> : <>&#43;</>}</span>
                    {item.question}
                  </h3>
                  
                </div>
                
                <div className={index === activeIndex? 'answer active' : 'answer'}>
                  <div className="inner-answer">
                    {item.answer}
                  </div>
                </div>
 
              </div>
            ))}
          </div>

        </div>
      </section>
    </Fragment>
  )
}

export default FAQ;