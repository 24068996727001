import { apiBaseUrl } from "../assets/js/blogConfig";
import { buildTree } from "./utils";

export const fetchRoadmapData = async () => {
    const res = await fetch(`${apiBaseUrl}roadmap?_embed=true&per_page=1000`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      }
    })
    .then((response) => response.json())
    .then((res) => {
        return res;
    })
    .catch((error) => {
        console.log("Error when trying to get roadmap details", error);
        return error;
    });

    return res;
};

export const fetchKnowledgeBaseCategories = async () => {
  const res = await fetch(`${apiBaseUrl}knowledgebase_category?orderby=id&per_page=100&order=asc`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    }
  })
  .then((response) => response.json())
  .then((res) => {

    return buildTree(res);
  })
  .catch((error) => {
      console.log("Error when trying to get knowledge base categories", error);
      return error;
  });

  return res;
};

export const fetchKnowledgeBaseItems = async () => {
  const res = await fetch(`${apiBaseUrl}knowledgebase?orderby=id&per_page=1000&order=asc&_embed=wp:term`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    }
  })
  .then((response) => response.json())
  .then((res) => {

    return res;
  })
  .catch((error) => {
      console.log("Error when trying to get knowledge base items", error);
      return error;
  });

  return res;
};