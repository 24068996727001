import "./bottomsec.css";
import { useNavigate } from "react-router-dom";

const BottomSec = ({isMobile=false}) => {

    const navigate = useNavigate();

    return (
        <section className={`bottom_sec ${isMobile? 'is-mobile': 'is-desktop'}`}>
            <div className="content_sec">
                <div className="squares_wrap">
                    <div className="outer_square">
                        <div className="mid_square">
                                <div className="main_square">
                                    {false && <h2>Don't risk it, SharePass it!</h2>}
                                    <a onClick={() => {navigate('/signup')}} target="_blank" >
                                    <button className="btns">Sign up</button>
                                    </a>
                                </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default BottomSec;

