import "./multiple.css";
import { useState, useEffect } from "react";
import { reloadScript } from "../../../services/utils";
import TinyTitle from "../../../components/tinytitle/TinyTitle";
import multiplatform from "../../../assets/images/Multiplatform-Solutions.png"
import { InView } from 'react-intersection-observer';
import LearnMore from "../../../components/learnmore/LearnMore";

const Multiple = ({ title, para, image }) => {
    const [visible, setVisible] = useState(false);
    const [visibleSec, setVisibleSec] = useState(false);

    
    useEffect(() => {
        setTimeout(() => window.scrollTo(0, 0), 500);
            
        reloadScript('https://b.sf-syn.com/badge_js?sf_id=3518941&variant_id=sf');
        reloadScript('https://b.sf-syn.com/badge_js?sf_id=3518941&variant_id=sd');

        //silentbreachInit();
        /*const silentBreachWidgetScript = document.getElementById('widget-silentbreach');

        if (!silentBreachWidgetScript) {
            const script = document.createElement('script');
            script.id = 'widget-silentbreach';
            script.src = 'https://silentbreach.com/certificates/load.js';
            document.body.appendChild(script);
        }

        if (silentBreachWidgetScript) {
            // Page was loaded directly from the browser.
            setTimeout(() => {console.log('LOAD!');
                var evt = document.createEvent('Event');  
                evt.initEvent('load', false, false);  
                window.dispatchEvent(evt);
            },3000);
        }*/

        return () => {
            // Clean up by removing the script when the component unmounts
            /*if (silentBreachWidgetScript) {
                silentBreachWidgetScript.parentNode.removeChild(silentBreachWidgetScript);
            }*/
        };
    }, []);

    const silentbreachInit = () => {
        var div = document.getElementById('silentbreach-badge');
        if (div != null) {
            var root = 'https://silentbreach.com/certificates/';
            var img = new Image();
            img.src = root + 'd.png';
            var customerid = silentbreachGetCustomerID(div);
            var path = root + customerid + "/";
            var link = document.createElement('a');
            link.setAttribute('href', path);
            link.appendChild(img);
            div.appendChild(link);
            setTimeout(function() {
                if (!silentbreachIsExpired(path + 't.txt')) {
                    img.src = path + 'b.png'
                }
            }, 500)
        }
    }
    
    function silentbreachGetCustomerID(div) {
        var customerid = null;
        if (div != null) {
            customerid = div.getAttribute('customerid')
        }
        if (customerid == null) {
            customerid = window.location.pathname.replace(/\/certificates\//, "").replace(/\//, "")
        }
        return customerid
    }
    
    function silentbreachHttpGet(theUrl) {
        var xmlHttp = new XMLHttpRequest();
        xmlHttp.open("GET", theUrl, false);
        xmlHttp.send(null);
        return xmlHttp.responseText
    }
    
    function silentbreachIsExpired(file) {
        var t_expiration_date = silentbreachHttpGet(file);
        var d = new Date();
        var t_now = d.getTime();
        t_expiration_date = t_expiration_date * 1000;
        return t_expiration_date < t_now
    }

    return (
        <section className="multiple">
            <InView 
                onChange={(inView, entry) => {
                    if (inView) {
                        setVisible(true)
                    }else{
                        setVisible(false)
                    }
                }} 
            >
                <div className="content_sec" style={{marginBottom:'100px',opacity:visible ? 1 : 0, transition:'all 1.5s ease-in-out'}}>
                    <TinyTitle title='Ecosystem' textcolor="#fff" />
                    <h2>Multi-platform unified privacy</h2>
                    <p>Recognizing the complexities of handling and sharing personal and sensitive details, SharePass has crafted a comprehensive toolkit to streamline and fortify the process. Our suite comprises a mobile app integrated with a keyboard, a user-friendly web application, and a dynamic browser extension. With the mobile app, users can confidently type secure data on any platform, while the browser extension ensures that functionality when browsing. The web application features an intuitive dashboard, empowering users to oversee their data, configure permissions, and determine access.</p>
                    <div className="img_wrap">   
                        <img src={multiplatform} />
                    </div>

                    <div style={{margin: '50px 0'}}>
                                        <LearnMore
                                            title="Learn More"
                                            top='0px'
                                            boxcolor="green"
                                            textcolor="#fff"
                                            path="/ecosystem"

                                        />
                                        </div>
                </div>
            </InView>
            

            { false && <InView 
                onChange={(inView, entry) => {
                    if (inView) {
                        setVisibleSec(true)
                    }else{
                        setVisibleSec(false)
                    }
                }} 
            >
                <div className="content_sec" style={{opacity:visibleSec ? 1 : 0, transition:'all 1.5s ease-in-out'}}>
                    <TinyTitle title='Certified security' textcolor="#fff" />
                    <h2>Security is Our Priority</h2>

                    <p>At SharePass, upholding stringent cybersecurity standards is central to our ethos. We engage third-party cybersecurity experts for thorough evaluations, encompassing in-depth code scrutiny, cloud infrastructure audits, and penetration tests. These routine yet detailed assessments enable us to remain abreast with current security methodologies and continuously optimize our protective measures. The certification emblem proves our unwavering adherence to these rigorous evaluations. Click on the logo for an independent validation, reflecting our unyielding commitment to security and transparency.</p>
                    
                    <div
                        id="silentbreach-badge"
                        customerid="sharepass-8bbe2cd36ee63289b9e9f8d6b4ca4b4c"
                    >  
                    </div>
                </div>
            </InView>}
        </section>
    )
}
export default Multiple;