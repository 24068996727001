import { useEffect } from "react";
import TinyTitle from "../../components/tinytitle/TinyTitle";
import { useNavigate } from "react-router-dom";
import Helmet from 'react-helmet';
import {defaultMetaDescription, defaultOgImg} from "../../assets/js/blogConfig";

const VideoLibrary = (props) => {
    useEffect(() => {
        setTimeout(() => window.scrollTo(0, 0), 500);
    },[]);
    const navigate = useNavigate();

    return(<>
        <Helmet>
            <title>Video Library | SharePass</title>
            <meta property="description" content={defaultMetaDescription} />
            <meta property="og:title" content="Video Library | SharePass" />
            <meta property="og:description" content={defaultMetaDescription} />
            <meta property="og:image" content={defaultOgImg} />
        </Helmet>
        <section className="about_landing">
            <div className="content_sec">
                <div className="text_wraper">
                    <TinyTitle className='text-center' title="How to" />
                    <h1 className='text-center'>Video Library</h1>
                    <p className="para">Explore SharePass products with our comprehensive video tutorial series. Designed to guide you seamlessly through every feature and functionality, these videos offer in-depth insights and step-by-step instructions for our range of products.</p>
                </div>
            </div>

            <div className="content_sec">
               
                <div className="partners-distributor-container mb-5">
                    <div onClick={() => navigate("/howto/webportal")} className="partners-distributor-item dark-bg vl-item vl-bg-webapp-item">
                        <div className="partners-distributor-item-content">
                            <h3 className="mb-2">SharePass Web Portal</h3>
                            <p className="mb-2">Introducing the SharePass Web Portal Tutorials: Open access for everyone seeking a straightforward way to share information securely with our free version.</p>
                        </div>
                    </div>
                    <div onClick={() => navigate("/howto/sharepassone")} className="partners-distributor-item dark-bg vl-item vl-bg-one-item">
                        <div className="partners-distributor-item-content" >
                            <h3 className="mb-2">SharePass One</h3>
                            <p className="mb-2">Explore the SharePass One Tutorials: Tailored for businesses and enterprises, SharePass One stands out as our premier product. Delve into its advanced functionalities through this comprehensive video series and maximize your SharePass experience</p>
                        </div>
                    </div>
                    <div className="partners-distributor-item dark-bg vl-item vl-bg-mobile-item">
                        <div className="partners-distributor-item-content" >
                            <h3 className="mb-2">SharePass Mobile</h3>
                            <p className="mb-2">Discover the SharePass Mobile Tutorials: Navigate the features of SharePass across iOS and Android platforms, inclusive of our specialized privacy keyboard.</p>
                            <small>Coming soon</small>
                        </div>
                    </div>
                    <div className="partners-distributor-item dark-bg vl-item vl-bg-extension-item">
                        <div className="partners-distributor-item-content" >
                            <h3 className="mb-2">SharePass Extension</h3>
                            <p className="mb-2">Unveiling the SharePass Browser Extension Tutorials: Dive into the SharePass browser extension and harness its features seamlessly within your web experience.</p>
                            <small>Coming soon</small>
                        </div>
                    </div>
                    <div className="partners-distributor-item dark-bg vl-item vl-bg-workshop-item">
                        <div className="partners-distributor-item-content" >
                            <h3 className="mb-2">SharePass Workshops</h3>
                            <p className="mb-2">Explore our SharePass workshops, showcasing real-world use cases and examples of how both our clients and we effectively utilize SharePass.</p>
                            <small>Coming soon</small>
                        </div>
                    </div>
                </div>

            </div>
        </section>
    </>
    )
}

export default VideoLibrary;