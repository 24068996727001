import { useEffect, useState, Fragment } from "react";
import './ourcustomers.css';
import Carousel from "../../../components/carousel/Carousel";

import centrevisionLogo from "../../../assets/files/centrevision.png";
import natanLogo from "../../../assets/files/natan.png";
import silentbreachLogo from "../../../assets/files/silentbreach.png";
import schurLogo from "../../../assets/files/schur.png";
import ymtechLogo from "../../../assets/files/ymtech.png";
import cfcLogo  from "../../../assets/files/centre-for-cs.png";
import certilaneLogo  from "../../../assets/files/certilane.png";

const OurCustomers = () => {
    const [client, setClient] = useState([]);
    
    /*useEffect(() => {
        if(client.length > 0) return;
        
        setClient([
            {
            "_id": "646a6ee4d2b09383e62b3a6b",
            "image": require("../../../assets/files/centrevision.png"),
            "createdAt": "2023-05-21T19:20:04.997Z",
            "updatedAt": "2023-05-21T19:20:04.997Z",
            "__v": 0
            },
            {
            "_id": "646a6ef3d2b09383e62b3a6f",
            "image": require("../../../assets/files/natan.png"),
            "createdAt": "2023-05-21T19:20:19.275Z",
            "updatedAt": "2023-05-21T19:20:19.275Z",
            "__v": 0
            },
            {
            "_id": "646a6efcd2b09383e62b3a73",
            "image": require("../../../assets/files/silentbreach.png"),
            "createdAt": "2023-05-21T19:20:28.542Z",
            "updatedAt": "2023-05-21T19:20:28.542Z",
            "__v": 0
            },
            {
            "_id": "646a6f0cd2b09383e62b3a77",
            "image": require("../../../assets/files/schur.png"),
            "createdAt": "2023-05-21T19:20:44.123Z",
            "updatedAt": "2023-05-21T19:20:44.123Z",
            "__v": 0
            },
            {
            "_id": "646a6f24d2b09383e62b3a7b",
            "image": require("../../../assets/files/ymtech.png"),
            "createdAt": "2023-05-21T19:21:08.307Z",
            "updatedAt": "2023-05-21T19:21:08.307Z",
            "__v": 0
            },
            {
            "_id": "646a6f31d2b09383e62b3a7f",
            "image": require("../../../assets/files/centre-for-cs.png"),
            "createdAt": "2023-05-21T19:21:21.955Z",
            "updatedAt": "2023-05-21T19:21:21.955Z",
            "__v": 0
            },
            {
            "_id": "64612ee4d2b09383e62b3a6b",
            "image": require("../../../assets/files/certilane.png"),
            "createdAt": "2023-05-21T19:20:04.997Z",
            "updatedAt": "2023-05-21T19:20:04.997Z",
            "__v": 0
            },
        ]);
    }, []);*/
    return (
        <div className='our_customers'>
            <div className='content_sec' >          
                <h2>Our Customers</h2>
                <p>At SharePass, our customers are central to our mission. We offer a platform focused on data privacy and security, enabling users to manage their personal and confidential details. Be part of our community and relish the assurance that your data remains protected and accessed with permission and control.</p>
                <div className="clients_slider">
                    {/*<div className="marquee" style={{ animationDuration: "25s" }}>
                        {
                          client && client.map((post, j) => (
                                <Fragment key={j}>
                                <a onClick={() => {return;}} >
                                    <img
                                        src={post.image}
                                        title=""
                                        className="marqueelogo"
                                        style={{ width: "auto", maxWidth: "auto", maxHeight: '77px' }}

                                    />
                                </a>
                                </Fragment>
                            ))
                        }
                    </div>
                    <div className="marquee" style={{ animationDuration: "25s" }}>
                        {
                            client.map((post, k) => (
                                <Fragment key={k}>
                                <a onClick={() => {return;}} >
                                    <img
                                        src={post.image}
                                        title=""
                                        className="marqueelogo"
                                        style={{ width: "auto", maxWidth: "auto", maxHeight: '77px' }}
                                    />
                                </a>
                                </Fragment>
                            ))
                        }

                    </div>*/}

                    <Carousel 
                        items={[
                            {
                                "image": centrevisionLogo,
                            },
                            {
                                "image": natanLogo,
                            },
                            {
                                "image": silentbreachLogo,
                            },
                            {
                                "image": schurLogo,
                            },
                            {
                                "image": ymtechLogo,
                            },
                            {
                                "image": cfcLogo,
                            },
                            {
                                "image": certilaneLogo,
                            }
                        ]} 
                        
                    />
                </div>
            </div>

        </div>
    )
}

export default OurCustomers;